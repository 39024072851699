import React, { useState, useEffect } from 'react';
import { ProjectList } from "../../../data/ProjectData";
import {
  Card,
  CardRight,
  TechCardContainer,
  TechCard,
  BtnGroup,
} from "./ProjectCardElements";
import { useNavigate } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import './ProjectCard.css'; 

import { CircleLoader } from 'react-spinners'; // Importer CircleLoader



function ProjectCard({ selectedCategory }) { 
  const [filteredProjects, setFilteredProjects] = useState([]);
  const navigate = useNavigate();
  const [totalImages, setTotalImages] = useState(0);
  const [loadedImages, setLoadedImages] = useState(0);
  const [isFullyLoaded, setIsFullyLoaded] = useState(false);

  useEffect(() => {
    const newFilteredProjects = selectedCategory
      ? ProjectList.filter(project => project.category === selectedCategory)
      : ProjectList;

    setFilteredProjects(newFilteredProjects);

    let count = 0;
    newFilteredProjects.forEach((project) => {
      count += project.isCarousel ? project.carousel_images.length : 1;
    });

    setTotalImages(count);
    setLoadedImages(0);
    setIsFullyLoaded(false); 
  }, [selectedCategory]); 

  const handleImageLoad = () => {
    setLoadedImages(prev => {
      const newLoadedImages = prev + 1;
      // Vérifier si toutes les images sont chargées
      if (newLoadedImages === totalImages) {
        setIsFullyLoaded(true);
      }
      return newLoadedImages;
    });
  };


  const handleProjectClick = (project) => {
    navigate(`/project/projectId:${project.id}`, {
      state: {
        title: project.title,
        images: project.images,
        descriptions: project.descriptions,
        conclusion: project.conclusion,
        is_video: project.is_video,
        video_link: project.video_link,
      },
    });
  };

  return (
    <>
      {/* Affichage du loader tant que le contenu n'est pas totalement chargé */}
      {!isFullyLoaded && (
        <div className="loader">
          <CircleLoader color="#3498db" size={150} /> {/* Cercle de chargement avec taille et couleur personnalisées */}
        </div>
      )}
    
      {/* Affichage des projets une fois qu'ils sont chargés */}
      <div style={{ visibility: isFullyLoaded ? "visible" : "hidden" }}>
        {filteredProjects.length > 0 ? (
          filteredProjects.map((list, index) => (
            <Card key={index}>
              {list.isCarousel ? (
                <Carousel interval={null}>
                  {list.carousel_images.map((image, idx) => (
                    <Carousel.Item key={idx}>
                      <img
                        src={image}
                        alt={`carousel-${idx}`}
                        className="d-block w-100 carousel-img"
                        onLoad={() => handleImageLoad(`${index}-${idx}`)} // Appel du handleImageLoad pour chaque image
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              ) : (
                <img
                  src={list.img}
                  alt={list.name}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  onLoad={() => handleImageLoad(index)} // Appel du handleImageLoad pour les images seules
                />
              )}
              <CardRight>
                <h4 style={{ whiteSpace: "pre-line" }}>{list.title}</h4>
                <p style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: list.description }}></p>
                <TechCardContainer>
                  {list.tech_stack.map((tech, techIndex) => (
                    <TechCard key={techIndex}>{tech}</TechCard>
                  ))}
                </TechCardContainer>
                <BtnGroup>
                  {list.github_url.length > 0 && (
                    <a
                      className="btn SecondaryBtn btn-shadow"
                      href={list.github_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Github ➜
                    </a>
                  )}
                  {list.demo_url.length > 0 && (
                    <a
                      className="btn PrimaryBtn btn-shadow"
                      href={list.demo_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Visit ➜
                    </a>
                  )}
                  {list.project_demo.length > 0 && (
                    <button
                      className="btn PrimaryBtn btn-shadow"
                      onClick={() => handleProjectClick(list)}
                    >
                      Project Details Page ➜
                    </button>
                  )}
                </BtnGroup>
              </CardRight>
            </Card>
          ))
        ) : (
          <p>No projects found for the selected category.</p>
        )}
      </div>
    </>
  );
}

export default ProjectCard;
