import React, { useEffect, useState } from "react";
import { useParams, useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import "./Project.scss"

const Project = () => {
  const { state } = useLocation();
  const { title, images, descriptions, conclusion, is_video, video_link } = state || {};
  const [fullscreenImage, setFullscreenImage] = useState(null);


  const openFullscreen = (imageUrl) => {
    setFullscreenImage(imageUrl);
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    console.log(is_video)
  }, []);

  const isWideScreen = window.innerWidth > 1000;

  return <>
    {isWideScreen && (
      <Modal show={!!fullscreenImage} onHide={closeFullscreen} centered>
        <Modal.Body>
          <img className="fullscreen-image" src={fullscreenImage} alt="Fullscreen" />
        </Modal.Body>
      </Modal>
    )}

    <div className="project-page">
      <div className="project-section">
        <h1>{title}</h1>
        <div className="line-box"></div>
        <div className="project-blocks">

          {is_video && (
            <div className="project-video">
              <iframe
                src={video_link}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Video"
              ></iframe>
            </div>
          )}

          {images && images.length > 0 ? (
            images.map((image, index) => (
              <div className="project-block" key={index}>
                {/* Affichage de l'image */}
                <div className="project-image">
                  <img
                    src={image}
                    alt={`Project ${index + 1}`}
                    onClick={() => openFullscreen(image)}
                  />
                </div>

                {/* Affichage de la description si elle existe pour cet index */}
                {descriptions && descriptions[index] && (
                  <p
                    className="description"
                    style={{
                      whiteSpace: "pre-line",
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                      overflow: "visible",
                    }}
                    dangerouslySetInnerHTML={{ __html: descriptions[index] }}
                  />
                )}
              </div>
            ))
          ) : (
            descriptions && descriptions.map((description, index) => (
              <div className="project-block" key={index}>
                {description && (
                  <p
                    className="description"
                    style={{
                      whiteSpace: "pre-line",
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                      overflow: "visible",
                    }}
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                )}
              </div>
            ))
          )}

        </div>

        <br></br>
        {/* <h2 className="col-12" style={{ fontSize: "1.5em", color: "white", marginTop: "40px", marginBottom: "80px" }}>
          {conclusion}
        </h2> */}
      </div>

      
    </div>
  </>;
}

export default Project;
